import React from 'react'
import Layout from '../components/layout'
import '../styles/privacy-policy.scss'
import SEO from '../components/seo'

const PrivacyPolicy = () => {
  return (
    <>
      <Layout>
        <SEO
          title="Lumineers Website | Privacy Policy"
          description="By your access and use of the Lumineers website, ordering products and services, or otherwise transacting with Den-Mat Holdings, LLC, you hereby agree to the terms of our Privacy Policy."
        />
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h1 className="mt-5">Privacy Policy</h1>
              <p>
                <span style={{ fontSize: `18px` }}>
                  DenMat respects your privacy and is committed to protecting
                  your personal data. This privacy policy will inform you as to
                  how we look after your personal data when you visit our
                  website (regardless of where you visit it from) and tell you
                  about your privacy rights and how the law protects you.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  This privacy policy is provided in a layered format so you can
                  click through to the specific areas set out below. Please also
                  use the Glossary to understand the meaning of some of the
                  terms used in this privacy policy.
                </span>
              </p>

              <ol>
                <li>
                  <a href="#important-info">
                    IMPORTANT INFORMATION AND WHO WE ARE
                  </a>
                </li>
                <li>
                  <a href="#hippa">HIPPA</a>
                </li>
                <li>
                  <a href="#collect-data">THE DATA WE COLLECT ABOUT YOU</a>
                </li>
                <li>
                  <a href="#personal-data">
                    HOW IS YOUR PERSONAL DATA COLLECTED?
                  </a>
                </li>
                <li>
                  <a href="#personal-data-use">HOW WE USE YOUR PERSONAL DATA</a>
                </li>
                <li>
                  <a href="#disclosures">DISCLOSURES OF YOUR PERSONAL DATA</a>
                </li>
                <li>
                  <a href="#international-transfer">INTERNATIONAL TRANSFERS</a>
                </li>
                <li>
                  <a href="#data-security">DATA SECURITY</a>
                </li>
                <li>
                  <a href="#data-retention">DATA RETENTION</a>
                </li>
                <li>
                  <a href="#legal-rights">YOUR LEGAL RIGHTS</a>
                </li>
                <li>
                  <a href="#glossary">GLOSSARY</a>
                </li>
              </ol>

              <ol>
                <li id="important-info">
                  <span style={{ fontSize: `18px` }}>
                    IMPORTANT INFORMATION AND WHO WE ARE
                  </span>
                </li>
              </ol>
              <h4>
                <span style={{ fontSize: `18px` }}>
                  PURPOSE OF THIS PRIVACY POLICY
                </span>
              </h4>
              <p>
                <span style={{ fontSize: `18px` }}>
                  This privacy policy aims to give you information on how DenMat
                  collects and processes your personal data through your use of
                  this website, including any data you may provide through this
                  website when you purchase a product or sign up for educational
                  courses.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  This website is not intended for children and we do not
                  knowingly collect data relating to children.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  It is important that you read this privacy policy together
                  with any other privacy policy or fair processing policy we may
                  provide on specific occasions when we are collecting or
                  processing personal data about you so that you are fully aware
                  of how and why we are using your data. This privacy policy
                  supplements other notices and privacy policies, if any, and is
                  not intended to override them.
                </span>
              </p>

              <h4>
                <span style={{ fontSize: `18px` }}>CONTROLLER</span>
              </h4>
              <p>
                <span style={{ fontSize: `18px` }}>
                  DenMat is made up of different legal entities, details of
                  which can be found here:&nbsp;
                  <a
                    href="https://denmat.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    denmat.com
                  </a>
                  . This privacy policy is issued on behalf of the DenMat Group
                  so when we mention “DenMat”, “we”, “us,” or “our” in this
                  privacy policy, we are referring to the relevant company in
                  the DenMat Group responsible for processing your data.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  We have appointed a data privacy manager who is responsible
                  for overseeing questions in relation to this privacy policy.
                  If you have any questions about this privacy policy, including
                  any requests to exercise your legal rights, please contact us
                  at set forth below:
                </span>
              </p>

              <h4>
                <span style={{ fontSize: `18px` }}>CONTACT DETAILS</span>
              </h4>
              <p>
                <span style={{ fontSize: `18px` }}>
                  If you have any questions about this privacy policy or our
                  privacy practices, please contact us in the following ways:
                </span>
              </p>
              <p>
                <span style={{ fontSize: `18px` }}>
                  Full name of legal entity: Den-Mat Holdings, LLC
                </span>
              </p>
              <p>
                <span style={{ fontSize: `18px` }}>
                  Website address:&nbsp;
                  <a
                    href="https://denmat.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    denmat.com
                  </a>
                </span>
              </p>
              <p>
                <span style={{ fontSize: `18px` }}>
                  Postal address: 1017 W. Central Avenue, Lompoc, California,
                  93436, United States of America
                </span>
              </p>
              <p>
                <span style={{ fontSize: `18px` }}>
                  Telephone number: +1 (800) 433 6648
                </span>
              </p>

              <h4>
                <span style={{ fontSize: `18px` }}>
                  CHANGES TO THE PRIVACY POLICY AND YOUR DUTY TO INFORM US OF
                  CHANGES
                </span>
              </h4>
              <p>
                <span style={{ fontSize: `18px` }}>
                  We keep our privacy policy under regular review.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  It is important that the personal data we hold about you is
                  accurate and current. Please keep us informed if your personal
                  data changes during your relationship with us.
                </span>
              </p>

              <h4>
                <span style={{ fontSize: `18px` }}>THIRD-PARTY LINKS</span>
              </h4>
              <p>
                <span style={{ fontSize: `18px` }}>
                  This website may include links to third-party websites,
                  plug-ins and applications. Clicking on those links or enabling
                  those connections may allow third parties to collect or share
                  data about you. We do not control these third-party websites
                  and are not responsible for their privacy statements. When you
                  leave our website, we encourage you to read the privacy policy
                  of every website you visit.
                </span>
              </p>
              <ol start="2">
                <li id="hippa">
                  <span style={{ fontSize: `18px` }}>HIPPA</span>
                </li>
              </ol>
              <p>
                <span style={{ fontSize: `18px` }}>
                  DenMat Lab fully complies with HIPAA and all laws regarding
                  protected health information.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  A business associate agreement is not required by the Privacy
                  Rule for dental laboratory services that are customarily
                  provided by our lab pursuant to your prescriptions. The U.S.
                  Department of Health and Human Services drafted the Privacy
                  Rule so that it would not interfere with the sharing of
                  information among health care providers for the treatment of
                  patients.<sup>1</sup>&nbsp;The Privacy Rule classifies
                  dentists and the dental laboratories that fulfill dentists’
                  work orders for prosthetics as health care providers.
                  <sup>2</sup>&nbsp;Disclosures between the dental provider and
                  the dental laboratory for the treatment activities that the
                  lab provides are explicitly excepted from the business
                  associate requirements of the Privacy Rule.<sup>3</sup>
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  You will be interested to know that, "the Office of Civil
                  Rights, the Health and Human Services agency charged with
                  HIPAA Privacy Rule enforcement provisions, said OCR agrees
                  …that dental laboratories are health care providers, so no
                  Business Associate Agreement is required to share protected
                  health information for treatment purposes" (ADA News, "ADA,
                  NADL, OCR agree on status of Iabs," May 5, 2003, Page 6). Even
                  so, the lab does not receive patients’ telephone numbers,
                  addresses, social security numbers, medical records or data
                  directly identifying individuals’ relatives, employers or
                  household members ("Protected Health Information"). Names that
                  customers provide to DenMat Lab are only used by the lab to
                  identify their cases. For more information on HIPAA please
                  visit:{' '}
                  <a href="http://www.hhs.gov/ocr/privacy/hipaa/understanding/summary/index.html">
                    http://www.hhs.gov/ocr/privacy/hipaa/understanding/summary/index.html
                  </a>
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  1See 67 Federal Register 53252
                </span>
                <br />
                <span style={{ fontSize: `18px` }}>
                  2See 65 Federal Register 82568
                </span>
                <br />
                <span style={{ fontSize: `18px` }}>
                  3See Privacy Rule 164.502 (e)(1)
                </span>
              </p>
              <ol>
                <li id="collect-data">
                  <span style={{ fontSize: `18px` }}>
                    THE DATA WE COLLECT ABOUT YOU
                  </span>
                </li>
              </ol>
              <p>
                <span style={{ fontSize: `18px` }}>
                  Personal data, or personal information, means any information
                  about an individual from which that person can be identified.
                  It does not include data where the identity has been removed
                  (anonymous data).
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  We may collect, use, store and transfer different kinds of
                  personal data about you which we have grouped together as
                  follows:
                </span>
              </p>
              <ul>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    <strong>Identity Data</strong>&nbsp;includes first name,
                    maiden name, last name, username or similar identifier,
                    marital status, title, date of birth and gender.
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    <strong>Contact Data&nbsp;</strong>includes billing address,
                    delivery address, email address and telephone numbers.
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    <strong>Financial Data</strong>&nbsp;includes bank account
                    and payment card details.
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    <strong>Transaction Data</strong>&nbsp;includes details
                    about payments to and from you and other details of products
                    and services you have purchased from us.
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    <strong>Technical Data</strong>&nbsp;includes internet
                    protocol (IP) address, your login data, browser type and
                    version, time zone setting and location, browser plug-in
                    types and versions, operating system and platform, and other
                    technology on the devices you use to access this website.
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    <strong>Usage Data</strong>&nbsp;includes information about
                    how you use our website, products and services, including:
                  </span>
                  <ol>
                    <li>
                      <span style={{ fontSize: `18px` }}>
                        your meal and accommodation preferences when attending a
                        conference which we have sponsored; and
                      </span>
                    </li>
                    <li>
                      <span style={{ fontSize: `18px` }}>
                        information relating to DenMat products you use.
                      </span>
                    </li>
                  </ol>
                </li>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    <strong>Marketing and Communications Data</strong>
                    &nbsp;includes your preferences in receiving marketing from
                    us and our third parties and your communication preferences.
                  </span>
                </li>
              </ul>

              <p>
                <span style={{ fontSize: `18px` }}>
                  We also may collect, use and share&nbsp;
                  <strong>Aggregated Data</strong>&nbsp;such as statistical or
                  demographic data for any purpose. Aggregated Data could be
                  derived from your personal data but is not considered personal
                  data in law as this data will not directly or indirectly
                  reveal your identity. For example, we may aggregate your Usage
                  Data to calculate the percentage of users accessing a specific
                  website feature. However, if we combine or connect Aggregated
                  Data with your personal data so that it can directly or
                  indirectly identify you, we treat the combined data as
                  personal data which will be used in accordance with this
                  privacy policy.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  We may process special categories of personal information. The
                  General Data Protection Regulation (GDPR) promulgated by the
                  European Union, effective May 2018, provides that this
                  includes data concerning your health, personal data revealing
                  your racial or ethnic origin, political opinions, religious or
                  philosophical beliefs, trade union membership, genetic or
                  biometric data, data concerning sexual orientation or data
                  relating to a criminal record or alleged criminal activity.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  We will only process and share special categories of personal
                  information for marketing and event management purposes (for
                  example in relation to your dietary or accommodation
                  preferences, if necessary for a particular event) and we will
                  not keep such data longer than is needed for such event.
                </span>
              </p>

              <h4>
                <span style={{ fontSize: `18px` }}>
                  IF YOU FAIL TO PROVIDE PERSONAL DATA
                </span>
              </h4>
              <p>
                <span style={{ fontSize: `18px` }}>
                  Where we need to collect personal data by law, or under the
                  terms of a contract we have with you, and you fail to provide
                  that data when requested, we may not be able to perform the
                  contract we have or are trying to enter into with you (for
                  example, to provide you with goods or services). In this case,
                  we may have to cancel a product or service you have with us,
                  but we will notify you if this is the case at the time.
                </span>
              </p>
              <ol start="4">
                <li id="personal-data">
                  <span style={{ fontSize: `18px` }}>
                    HOW IS YOUR PERSONAL DATA COLLECTED?
                  </span>
                </li>
              </ol>
              <p>
                <span style={{ fontSize: `18px` }}>
                  We use different methods to collect data from and about you
                  including through:
                </span>
              </p>
              <ul>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    <strong>Direct interactions.</strong>&nbsp;You may give us
                    your Identity, Contact and Financial Data by filling in
                    forms or by corresponding with us by post, phone, email or
                    otherwise. This includes personal data you provide when you:
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    seek to learn about or purchase our products;
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    subscribe to our product publications;
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    request marketing to be sent to you;
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    enter a competition, promotion or survey; or
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    give us feedback or contact us.
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    <strong>Automated technologies or interactions.</strong>
                    &nbsp;As you interact with our website, we will
                    automatically collect Technical Data about your equipment,
                    browsing actions and patterns. We collect this personal data
                    by using cookies, server logs and other similar
                    technologies. We may also receive Technical Data about you
                    if you visit other websites employing our cookies.
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    <strong>
                      Third parties or publicly available sources.
                    </strong>{' '}
                    We will receive personal data about you from various third
                    parties and public sources but only where necessary to
                    provide our services and products to you.
                  </span>
                  &nbsp;
                </li>
              </ul>
              <ul>
                <li style={{ listStyleType: `none` }}>
                  <ul>
                    <li>
                      <strong style={{ fontSize: `18px` }}>(a)</strong>{' '}
                      analytics providers such as Google based inside and
                      outside the U.S.
                    </li>
                    <li>
                      <strong style={{ fontSize: `18px` }}>(b)</strong> search
                      information providers based inside and outside the U.S.
                    </li>
                  </ul>
                </li>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    Contact, Financial and Transaction Data from providers of
                    technical, payment and delivery services based inside and
                    outside the U.S.
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    Identity and Contact Data from publicly available sources
                    such as Companies House and the Electoral Register based
                    inside the U.S.
                  </span>
                </li>
              </ul>

              <ol start="5">
                <li id="personal-data-use">
                  <span style={{ fontSize: `18px` }}>
                    HOW WE USE YOUR PERSONAL DATA
                  </span>
                </li>
              </ol>
              <p>
                <span style={{ fontSize: `18px` }}>
                  We will only use your personal data when the law allows us to.
                  Most commonly, we will use your personal data in the following
                  circumstances:
                </span>
              </p>
              <ul>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    Where we need to perform the contract we are about to enter
                    into or have entered into with you.
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    Where it is necessary for our legitimate interests (or those
                    of a third party) and your interests and fundamental rights
                    do not override those interests.
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    Where we need to comply with a legal obligation.
                  </span>
                </li>
              </ul>

              <p>
                <span style={{ fontSize: `18px` }}>
                  See Section 10, below, to find out more about the types of
                  lawful basis that we will rely on to process your personal
                  data.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  Generally, we do not rely on consent as a legal basis for
                  processing your personal data although we will get your
                  consent before sending third party direct marketing
                  communications to you via email or text message. You have the
                  right to withdraw consent to marketing at any time by
                  contacting us.
                </span>
              </p>

              <h4>
                <span style={{ fontSize: `18px` }}>
                  PURPOSES FOR WHICH WE WILL USE YOUR PERSONAL DATA
                </span>
              </h4>
              <p>
                <span style={{ fontSize: `18px` }}>
                  We have set out below a description of all the ways we plan to
                  use your personal data, and which of the legal bases we rely
                  on to do so. We have also identified what our legitimate
                  interests are where appropriate.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  Note that we may process your personal data for more than one
                  lawful ground depending on the specific purpose for which we
                  are using your data. Please contact us if you need details
                  about the specific legal ground we are relying on to process
                  your personal data where more than one ground has been set out
                  in the table below.
                </span>
              </p>
              <ul>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    To register you as a new customer, we use your identity and
                    contact information. The lawful basis for this is the
                    necessity for performance of a contract with you.
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    To process and deliver your order including:
                  </span>
                  <ul>
                    <li>
                      <span style={{ fontSize: `18px` }}>
                        Manage payments, fees and charges; and
                      </span>
                    </li>
                    <li>
                      <span style={{ fontSize: `18px` }}>
                        Collect and recover money owed to us
                      </span>
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                <span style={{ fontSize: `18px` }}>
                  We use your identity, contact, financial, transaction, and
                  marketing and communications data. The lawful bases for this
                  are the necessity for performance of a contract with you and
                  our legitimate interests (to recover debts due to us).
                </span>
              </p>

              <ul>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    To manage our relationship with you which will include:
                  </span>
                  <ul>
                    <li>
                      <span style={{ fontSize: `18px` }}>
                        Notifying you about changes to our terms or privacy
                        policy; and
                      </span>
                    </li>
                    <li>
                      <span style={{ fontSize: `18px` }}>
                        Asking you to leave a review or take a survey,
                      </span>
                    </li>
                  </ul>
                </li>
              </ul>

              <p>
                <span style={{ fontSize: `18px` }}>
                  We use your identity, contact and marketing and communications
                  data. The lawful bases for this are the necessity for
                  performance of a contract with you, in order to comply with a
                  legal obligation and our legitimate interests (to keep our
                  records updated and to study how customers use our
                  products/services).
                </span>
              </p>
              <ul>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    To enable you to partake in a prize draw, competition or
                    complete a survey we use your identity, contact, financial,
                    transaction, and marketing and communications data. The
                    lawful bases for this are the necessity for performance of a
                    contract with you and our legitimate interests (to study how
                    customers use our products/services, to develop them and
                    grow our business).
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    To administer and protect our business and this website
                    (including troubleshooting, data analysis, testing, system
                    maintenance, support, reporting and hosting of data) we use
                    your identity, contact, technical data. The lawful bases for
                    this are in order to comply with a legal obligation and the
                    necessity for performance of a contract with (for running
                    our business, provision of administration and IT services,
                    network security, to prevent fraud and in the context of a
                    business reorganisation or group restructuring exercise).
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    To deliver relevant website content and advertisements to
                    you and measure or understand the effectiveness of the
                    advertising we serve to you we use your identity, contact,
                    financial, transaction, marketing and communications and
                    technical data. The lawful basis for this is our legitimate
                    interests (to study how customers use our products/services,
                    to develop them, to grow our business and to inform our
                    marketing strategy).
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    To use data analytics to improve our website,
                    products/services, marketing, customer relationships and
                    experiences we use your technical and usage data. The lawful
                    basis for this is our legitimate interests (to define types
                    of customers for our products and services, to keep our
                    website updated and relevant, to develop our business and to
                    inform our marketing strategy)
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    To make suggestions and recommendations to you about goods
                    or services that may be of interest to you we use your
                    identity, contact, financial, transaction, marketing and
                    communications and technical data. The lawful basis for this
                    is our legitimate interests (to develop our
                    products/services and grow our business).
                  </span>
                </li>
              </ul>

              <h4>
                <span style={{ fontSize: `18px` }}>MARKETING</span>
              </h4>
              <p>
                <span style={{ fontSize: `18px` }}>
                  We strive to provide you with choices regarding certain
                  personal data uses, particularly around marketing and
                  advertising.
                </span>
              </p>

              <h4>
                <span style={{ fontSize: `18px` }}>
                  PROMOTIONAL OFFERS FROM US
                </span>
              </h4>
              <p>
                <span style={{ fontSize: `18px` }}>
                  We may use your Identity, Contact, Technical, Usage and
                  Profile Data to form a view on what we think you may want or
                  need, or what may be of interest to you. This is how we decide
                  which products, services and offers may be relevant for you
                  (we call this marketing).
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  You will receive marketing communications from us if you have
                  requested information from us or purchased goods or services
                  from us and you have not opted out of receiving that
                  marketing.
                </span>
              </p>

              <h4>
                <span style={{ fontSize: `18px` }}>THIRD-PARTY MARKETING</span>
              </h4>
              <p>
                <span style={{ fontSize: `18px` }}>
                  We will get your express opt-in consent before we share your
                  personal data with any third party for marketing purposes.
                </span>
              </p>

              <h4>
                <span style={{ fontSize: `18px` }}>OPTING OUT</span>
              </h4>
              <p>
                <span style={{ fontSize: `18px` }}>
                  You can ask us or third parties to stop sending you marketing
                  messages at any time by contacting us.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  Where you opt out of receiving these marketing messages, this
                  will not apply to personal data provided to us as a result of
                  a product/service purchase, warranty registration,
                  product/service experience or other transactions.
                </span>
              </p>

              <h4>
                <span style={{ fontSize: `18px` }}>COOKIES</span>
              </h4>
              <p>
                <span style={{ fontSize: `18px` }}>
                  DenMat may use cookies to track the use of our website and to
                  facilitate and enhance your experience on our website. A
                  cookie is a piece of data stored on the user’s hard drive
                  containing information about the user, and is used in places
                  where the user must log into an area via a user name and
                  password, or to note the capabilities of a user’s web browser
                  in order to provide properly formatted content. For instance,
                  by setting a cookie on our website, the user would not have to
                  log in a password more than once, thereby saving time while on
                  our website. Usage of a cookie is in no way linked to any
                  personally identifiable information such as names or email
                  address while on our website. Most web browsers automatically
                  accept cookies, but you can disable this function so that your
                  browser will not accept cookies. Please be aware that
                  disabling this function may impact your use and enjoyment of
                  this website.
                </span>
              </p>

              <h4>
                <span style={{ fontSize: `18px` }}>CHANGE OF PURPOSE</span>
              </h4>
              <p>
                <span style={{ fontSize: `18px` }}>
                  We will only use your personal data for the purposes for which
                  we collected it, unless we reasonably consider that we need to
                  use it for another reason and that reason is compatible with
                  the original purpose. If you wish to get an explanation as to
                  how the processing for the new purpose is compatible with the
                  original purpose, please contact us.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  If we need to use your personal data for an unrelated purpose,
                  we will notify you and we will explain the legal basis which
                  allows us to do so.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  Please note that we may process your personal data without
                  your knowledge or consent, in compliance with the above rules,
                  where this is required or permitted by law.
                </span>
              </p>
              <ol start="6">
                <li id="disclosures">
                  <span style={{ fontSize: `18px` }}>
                    DISCLOSURES OF YOUR PERSONAL DATA
                  </span>
                </li>
              </ol>
              <p>
                <span style={{ fontSize: `18px` }}>
                  We may share your personal data with the parties set out below
                  for the purposes set out above in clause 4.
                </span>
              </p>
              <ul>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    Internal Third Parties as set out in the&nbsp;
                    <em>Glossary.</em>
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    External Third Parties as set out in the&nbsp;
                    <em>Glossary.</em>
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    Third parties to whom we may choose to sell, transfer or
                    merge parts of our business or our assets. Alternatively, we
                    may seek to acquire other businesses or merge with them. If
                    a change happens to our business, then the new owners may
                    use your personal data in the same way as set out in this
                    privacy policy.
                  </span>
                </li>
              </ul>
              <p>
                <span style={{ fontSize: `18px` }}>
                  We require all third parties to respect the security of your
                  personal data and to treat it in accordance with the law. We
                  do not allow our third-party service providers to use your
                  personal data for their own purposes and only permit them to
                  process your personal data for specified purposes and in
                  accordance with our instructions.
                </span>
              </p>
              <ol start="7">
                <li id="international-transfer">
                  <span style={{ fontSize: `18px` }}>
                    INTERNATIONAL TRANSFERS
                  </span>
                </li>
              </ol>
              <p>
                <span style={{ fontSize: `18px` }}>
                  We share your personal data within the DenMat Group. This will
                  involve transferring data outside the European Economic Area
                  (EEA).
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  We ensure your personal data is protected by requiring all our
                  group companies to follow the same rules when processing your
                  personal data. These rules are called “binding corporate
                  rules”.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  Many of our external third parties are based outside the EEA
                  so their processing of your personal data will involve a
                  transfer of data outside the EEA.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  Whenever we transfer your personal data out of the EEA, we
                  ensure a similar degree of protection is afforded to it by
                  ensuring at least one of the following safeguards is
                  implemented:
                </span>
              </p>
              <ul>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    We will only transfer your personal data to countries that
                    have been deemed to provide an adequate level of protection
                    for personal data by the European Commission.
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    Where we use certain service providers, we may use specific
                    contracts approved by the European Commission which give
                    personal data the same protection it has in Europe.
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    Where we use providers based in the US, we may transfer data
                    to them if they are part of the Privacy Shield which
                    requires them to provide similar protection to personal data
                    shared between Europe and the US.
                  </span>
                </li>
              </ul>
              <p>
                <span style={{ fontSize: `18px` }}>
                  Please contact us if you want further information on the
                  specific mechanism used by us when transferring your personal
                  data out of the EEA.
                </span>
              </p>
              <ol start="8">
                <li id="data-security">
                  <span style={{ fontSize: `18px` }}>DATA SECURITY</span>
                </li>
              </ol>
              <p>
                <span style={{ fontSize: `18px` }}>
                  We have put in place appropriate security measures to prevent
                  your personal data from being accidentally lost, used or
                  accessed in an unauthorized way, altered or disclosed. In
                  addition, we limit access to your personal data to those
                  employees, agents, contractors and other third parties who
                  have a business need to know. They will only process your
                  personal data on our instructions and they are subject to a
                  duty of confidentiality.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  We have put in place procedures to deal with any suspected
                  personal data breach and will notify you and any applicable
                  regulator of a breach where we are legally required to do so.
                </span>
              </p>
              <ol start="9">
                <li id="data-retention">
                  <span style={{ fontSize: `18px` }}>DATA RETENTION</span>
                </li>
              </ol>
              <h4>
                <span style={{ fontSize: `18px` }}>
                  FOR HOW LONG WILL YOU USE MY PERSONAL DATA?
                </span>
              </h4>
              <p>
                <span style={{ fontSize: `18px` }}>
                  We will only retain your personal data for as long as
                  reasonably necessary to fulfil the purposes we collected it
                  for, including for the purposes of satisfying any legal,
                  regulatory, tax, accounting or reporting requirements. We may
                  retain your personal data for a longer period in the event of
                  a complaint or if we reasonably believe there is a prospect of
                  litigation in respect to our relationship with you.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  To determine the appropriate retention period for personal
                  data, we consider the amount, nature and sensitivity of the
                  personal data, the potential risk of harm from unauthorised
                  use or disclosure of your personal data, the purposes for
                  which we process your personal data and whether we can achieve
                  those purposes through other means, and the applicable legal,
                  regulatory, tax, accounting or other requirements.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  In some circumstances you can ask us to delete your data: see
                  your legal rights below for further information.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  In some circumstances we will anonymise your personal data (so
                  that it can no longer be associated with you) for research or
                  statistical purposes, in which case we may use this
                  information indefinitely without further notice to you.
                </span>
              </p>
              <ol start="10">
                <li id="legal-rights">
                  <span style={{ fontSize: `18px` }}>YOUR LEGAL RIGHTS</span>
                </li>
              </ol>
              <p>
                <span style={{ fontSize: `18px` }}>
                  Under certain circumstances, you have rights under data
                  protection laws in relation to your personal data. These
                  rights include the following (see Section 10, below, for
                  details):
                </span>
              </p>
              <ul>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    Request access to your personal data.
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    Request correction of your personal data.
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    Request erasure of your personal data.
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    Object to processing of your personal data.
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    Request restriction of processing your personal data.
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    Request transfer of your personal data.
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    Right to withdraw consent.
                  </span>
                </li>
              </ul>
              <p>
                <span style={{ fontSize: `18px` }}>
                  If you wish to exercise any of the rights set out above,
                  please contact us.
                </span>
              </p>

              <h4>
                <span style={{ fontSize: `18px` }}>
                  NO FEE USUALLY REQUIRED
                </span>
              </h4>
              <p>
                <span style={{ fontSize: `18px` }}>
                  You will not have to pay a fee to access your personal data
                  (or to exercise any of the other rights). However, we may
                  charge a reasonable fee if your request is clearly unfounded,
                  repetitive or excessive. Alternatively, we could refuse to
                  comply with your request in these circumstances.
                </span>
              </p>

              <h4>
                <span style={{ fontSize: `18px` }}>
                  WHAT WE MAY NEED FROM YOU
                </span>
              </h4>
              <p>
                <span style={{ fontSize: `18px` }}>
                  We may need to request specific information from you to help
                  us confirm your identity and ensure your right to access your
                  personal data (or to exercise any of your other rights). This
                  is a security measure to ensure that personal data is not
                  disclosed to any person who has no right to receive it. We may
                  also contact you to ask you for further information in
                  relation to your request to speed up our response.
                </span>
              </p>

              <h4>
                <span style={{ fontSize: `18px` }}>TIME LIMIT TO RESPOND</span>
              </h4>
              <p>
                <span style={{ fontSize: `18px` }}>
                  We try to respond to all legitimate requests within one month.
                  Occasionally it could take us longer than a month if your
                  request is particularly complex or you have made a number of
                  requests. In this case, we will notify you and keep you
                  updated.
                </span>
              </p>
              <ol start="11">
                <li id="request">
                  <span style={{ fontSize: `18px` }}>DATA PRIVACY REQUEST</span>
                </li>
              </ol>

              <div class="ot-form-wrapper">
                <iframe
                  title="OneTrust"
                  src="https://privacyportal-cdn.onetrust.com/dsarwebform/986deef6-caf3-494e-8e37-f075d1616a6c/03d41457-66e7-4491-85d6-2f7f1f8ea7f5.html"
                ></iframe>
              </div>

              <ol start="12">
                <li id="glossary">
                  <span style={{ fontSize: `18px` }}>GLOSSARY</span>
                </li>
              </ol>
              <p>
                <span style={{ fontSize: `18px` }}>
                  Legitimate Interest means the interest of our business in
                  conducting and managing our business to enable us to give you
                  the best service/product and the best and most secure
                  experience. We make sure we consider and balance any potential
                  impact on you (both positive and negative) and your rights
                  before we process your personal data for our legitimate
                  interests. We do not use your personal data for activities
                  where our interests are overridden by the impact on you
                  (unless we have your consent or are otherwise required or
                  permitted to by law). You can obtain further information about
                  how we assess our legitimate interests against any potential
                  impact on you in respect of specific activities by contacting
                  us.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  Performance of Contract means processing your data where it is
                  necessary for the performance of a contract to which you are a
                  party or to take steps at your request before entering into
                  such a contract.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  Comply with a legal obligation means processing your personal
                  data where it is necessary for compliance with a legal
                  obligation that we are subject to.
                </span>
              </p>

              <h4>
                <span style={{ fontSize: `18px` }}>THIRD PARTIES</span>
              </h4>
              <h4>
                <span style={{ fontSize: `18px` }}>INTERNAL THIRD PARTIES</span>
              </h4>
              <p>
                <span style={{ fontSize: `18px` }}>
                  Other companies in the DenMat Group acting as joint
                  controllers or processors and who are based inside and outside
                  the U.S.
                </span>
              </p>

              <h4>
                <span style={{ fontSize: `18px` }}>EXTERNAL THIRD PARTIES</span>
              </h4>
              <p>
                <span style={{ fontSize: `18px` }}>
                  Service providers acting as processors based inside and
                  outside the U.S. who provide IT and system administration
                  services. Professional advisers acting as processors including
                  lawyers, bankers, auditors and insurers based inside and
                  outside the U.S. who provide consultancy, banking, legal,
                  insurance and accounting services. HM Revenue &amp; Customs,
                  regulators and other authorities acting as processors or joint
                  controllers based inside and outside the U.S. who require
                  reporting of processing activities in certain circumstances.
                </span>
              </p>

              <h4>
                <span style={{ fontSize: `18px` }}>YOUR LEGAL RIGHTS</span>
              </h4>
              <p>
                <span style={{ fontSize: `18px` }}>You have the right to:</span>
              </p>
              <p>
                <span style={{ fontSize: `18px` }}>
                  <strong>Request access</strong>&nbsp;to your personal data
                  (commonly known as a “data subject access request”). This
                  enables you to receive a copy of the personal data we hold
                  about you and to check that we are lawfully processing it.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  <strong>Request correction</strong>&nbsp;of the personal data
                  that we hold about you. This enables you to have any
                  incomplete or inaccurate data we hold about you corrected,
                  though we may need to verify the accuracy of the new data you
                  provide to us.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  <strong>Request erasure</strong>&nbsp;of your personal data.
                  This enables you to ask us to delete or remove personal data
                  where there is no good reason for us continuing to process it.
                  You also have the right to ask us to delete or remove your
                  personal data where you have successfully exercised your right
                  to object to processing (see below), where we may have
                  processed your information unlawfully or where we are required
                  to erase your personal data to comply with local law. Note,
                  however, that we may not always be able to comply with your
                  request of erasure for specific legal reasons which will be
                  notified to you, if applicable, at the time of your request.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  <strong>Object to processing</strong>&nbsp;of your personal
                  data where we are relying on a legitimate interest (or those
                  of a third party) and there is something about your particular
                  situation which makes you want to object to processing on this
                  ground as you feel it impacts on your fundamental rights and
                  freedoms. You also have the right to object where we are
                  processing your personal data for direct marketing purposes.
                  In some cases, we may demonstrate that we have compelling
                  legitimate grounds to process your information which override
                  your rights and freedoms.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  <strong>Request restriction</strong>&nbsp;of processing of
                  your personal data. This enables you to ask us to suspend the
                  processing of your personal data in the following scenarios:
                </span>
              </p>
              <ul>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    If you want us to establish the data’s accuracy.
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    Where our use of the data is unlawful but you do not want us
                    to erase it.
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    Where you need us to hold the data even if we no longer
                    require it as you need it to establish, exercise or defend
                    legal claims.
                  </span>
                </li>
                <li>
                  <span style={{ fontSize: `18px` }}>
                    You have objected to our use of your data but we need to
                    verify whether we have overriding legitimate grounds to use
                    it.
                  </span>
                </li>
              </ul>

              <p>
                <span style={{ fontSize: `18px` }}>
                  <strong>Request the transfer</strong>&nbsp;of your personal
                  data to you or to a third party. We will provide to you, or a
                  third party you have chosen, your personal data in a
                  structured, commonly used, machine-readable format. Note that
                  this right only applies to automated information which you
                  initially provided consent for us to use or where we used the
                  information to perform a contract with you.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  Withdraw consent at any time where we are relying on consent
                  to process your personal data. However, this will not affect
                  the lawfulness of any processing carried out before you
                  withdraw your consent. If you withdraw your consent, we may
                  not be able to provide certain products or services to you. We
                  will advise you if this is the case at the time you withdraw
                  your consent.
                </span>
              </p>

              <p>
                <span style={{ fontSize: `18px` }}>
                  (Revised Form 03-Sep-2020.)
                </span>
              </p>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default PrivacyPolicy
